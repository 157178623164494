import React, { useState } from "react";
import logo from "../assets/logo.jpeg";
import dataWorkExperience from "../utils/dataWorkExperience";
import { useSelector } from "react-redux";
import {
  AcademicCapIcon,
  BriefcaseIcon,
  LightBulbIcon,
  LinkIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import { TypeAnimation } from "react-type-animation";
import { LINK_EMAIL, LINK_FREECODECAMP, LINK_FREECODECAMP_JS, LINK_FREECODECAMP_RESPONSIVE } from "../utils/constants";

const About = ({ year, company, job, jobdesk }) => {
  const isDarkMode = useSelector((store) => store.mode.darkMode);

  return (
    <>
      <div className="mb-10 mt-28">
        <div
          className={`mx-80 xs:mx-8 sm:mx-8 md:mx-64 lg:mx-80  ${
            isDarkMode ? "text-white" : "text-gray-700"
          }`}
        >
          <div className="mb-5">
            <div className="flex items-center justify-start">
              <img
                src={logo}
                className="w-20 h-20 mr-5 xs:w-14 xs:h-14 md:w-20 md:h-20 lg:w-20 lg:h-20 rounded-full"
              />
              {/** Type Animation */}
              <div className="font-bold font-sans text-2xl xs:text-lg sm:text-lg md:text-xl lg:text-2xl text-pink-600">
                <TypeAnimation
                  preRenderFirstString={true}
                  sequence={[
                    500,
                    "Hello! I'm Mutmainnah 👋",
                    1000,
                    "I'm a Frontend Engineer 💻",
                    1000,
                    "I'm an Ambivert 👻",
                    1000,
                    "I Love Matcha 🍵",
                    500,
                  ]}
                  speed={60}
                  repeat={Infinity}
                />
            
                
              </div>
            </div>
            <div
              className={`border rounded-full mt-4 mb-4 ${
                isDarkMode ? "border-gray-900" : " border-gray-100"
              }`}
            ></div>

            <div className="mb-5">
              <div>
                <p
                  className={`text-base ${
                    isDarkMode ? "text-gray-300" : "text-gray-700"
                  }`}
                >
                  <span className="font-bold">Mutmainnah</span> is enthusiastic
                  about learning and exploring new things. She was a generalist
                  and has worked in various fields. One year ago, she was
                  inspired by the Mugiwara crew from{" "}
                  <span className="italic">One Piece</span>, who experienced a
                  "time skip" to sharpen their skills and become a great crew.
                  Motivated by their journey, she decided to dive deep into
                  software engineering, focusing on frontend development.
                  Now, she is committed and has begun her journey as{" "}
                  <span className="font-bold">a Frontend React Engineer</span>,
                  aiming to become a great software engineer.
                </p>
              </div>
              <a href={LINK_EMAIL}>
                <button
                aria-label="Contact Mutmainnah"
                className="text-center items-center gap-2 w-full mt-5 py-3 px-3 xs:text-base sm:text-base md:text-base lg:text-base rounded-lg cursor-pointer border-2 border-pink-600 hover:bg-pink-600 hover:text-gray-50 font-bold hover:drop-shadow-lg text-pink-600"
                >
                Interested in working together?<span> Let's have a chat! 📩</span>
                </button>
            </a>
            </div>

            <div
              className={`border rounded-full mt-4 mb-4 ${
                isDarkMode ? "border-gray-900" : "border-gray-100"
              }`}
            ></div>
          </div>

          <div>
            <h1 className="flex gap-2 font-bold text-lg text-pink-600 items-center my-5">
              <LightBulbIcon className="w-6 h-6 stroke-2 text-pink-600" />
              Skills{" "}
            </h1>
            <div
              className={`border rounded-full mt-4 mb-4 ${
                isDarkMode ? "border-gray-900" : "border-gray-100"
              }`}
            ></div>
            <div className="mb-8">
              <ol
                class={`relative border-s ${
                  isDarkMode
                    ? "border-pink-950"
                    : "border-pink-200"
                }`}
              >
                <li class="mb-5 ms-4">
                  <div
                    class={`absolute w-3 h-3 bg-pink-600 rounded-full mt-1.5 -start-1.5`}
                  ></div>

                  <h3
                    class={`text-lg font-semibold  ${
                      isDarkMode ? "text-gray-300" : "text-gray-700"
                    }`}
                  >
                    Technical Skills
                  </h3>
                  <p
                    class={`mb-4 text-base font-normal  ${
                      isDarkMode ? "text-gray-400" : "text-gray-700"
                    }`}
                  >
                    Javascript / Typescript / React Js / Redux / Tailwind CSS /
                    CSS / HTML / MySQL / Firebase / Jest / Git / Github / Web
                    Design / Wordpress / Elementor / Figma
                  </p>
                </li>
                <li class="mb-5 ms-4">
                <div
                    class={`absolute w-3 h-3 bg-pink-600 rounded-full mt-1.5 -start-1.5`}
                  ></div>
                  <h3
                    class={`text-lg font-semibold  ${
                      isDarkMode ? "text-gray-300" : "text-gray-700"
                    }`}
                  >
                    General Skills
                  </h3>
                  <p
                    class={`mb-4 text-base font-normal  ${
                      isDarkMode ? "text-gray-400" : "text-gray-700"
                    }`}
                  >
                    Project Management / Project Planning / Customer Support /
                    Teaching
                  </p>
                </li>
              </ol>
            </div>
          </div>

          <div
            className={`border rounded-full ${
              isDarkMode ? "border-gray-900" : "border-gray-100"
            }`}
          ></div>
          <h1 className=" flex items-center gap-2 font-bold text-lg my-4 text-pink-600">
            <BriefcaseIcon className="w-6 h-6 stroke-2 text-pink-600" />
            Work Experience{" "}
          </h1>
          <div
            className={`border rounded-full mt-4 mb-4 ${
              isDarkMode ? "border-gray-900" : "border-gray-100"
            }`}
          ></div>
          <div className="mb-10">
            {dataWorkExperience.map((data) => (
              <ol
                key={data.id}
                class={`relative border-s ${
                  isDarkMode ? "border-pink-950" : "border-pink-200 "
                }`}
              >
                <li class="mb-5 ms-4 mx-5">
                <div
                    class={`absolute w-3 h-3 bg-pink-600 rounded-full mt-1.5 -start-1.5`}
                  ></div>
                  <time class="mb-1 text-sm font-normal leading-none text-gray-400">
                    {data.info.year}
                  </time>
                  <h3
                    class={`text-lg font-semibold  ${
                      isDarkMode ? "text-gray-300" : "text-gray-700"
                    }`}
                  >
                    {data.info.job}
                  </h3>
                  <h3
                    class={`text-lg font-semibold  ${
                      isDarkMode ? "text-gray-300" : "text-gray-700"
                    }`}
                  >
                    {data.info.company}
                  </h3>
                  <p
                    class={`text-base font-normal  ${
                      isDarkMode ? "text-gray-400" : "text-gray-700"
                    }`}
                  >
                    {data.info.jobdesc}
                  </p>
                </li>
              </ol>
            ))}
          </div>
          <div>
            <div
              className={`border rounded-full mt-4 mb-4 ${
                isDarkMode ? "border-gray-900" : "border-gray-100"
              }`}
            ></div>
            <h1 className="flex gap-2 font-bold text-lg text-pink-600 items-center mt-2">
              <AcademicCapIcon className="text-pink-600 w-7 h-7 stroke-2" />
              Education
            </h1>
            <div
              className={`border rounded-full mt-4 mb-4 ${
                isDarkMode ? "border-gray-900" : "border-gray-100"
              }`}
            ></div>
            <div>
              <ol
                className={`relative border-s ${
                  isDarkMode
                    ? "border-pink-950"
                    : "border-pink-200 "
                }`}
              >
                <li className="mb-5 ms-4 mx-5">
                <div
                    class={`absolute w-3 h-3 bg-pink-600 rounded-full mt-1.5 -start-1.5`}
                  ></div>
                  <time className="mb-1 text-sm font-normal leading-none text-gray-400">
                    2023 - 2024 | Informal
                  </time>
                  <h3
                    className={`text-lg font-semibold  ${
                      isDarkMode ? "text-gray-300" : "text-gray-700"
                    }`}
                  >
                    Freecodecamp
                  </h3>
                 
                  <p
                    className={`mb-2 mt-2 text-base font-normal ${
                      isDarkMode ? "text-gray-400" : "text-gray-700"
                    }`}
                  >
                    Javascript Algorithms & Data Structures
                  </p>
                  <a href={LINK_FREECODECAMP_JS}>
                  <button className={`flex gap-2 items-center  text-base border border-gray-200 px-3 py-2 rounded-md font-normal hover:bg-pink-600 hover:text-gray-50 ${
                      isDarkMode ? "text-gray-400" : "text-gray-700"
                    }`}><LinkIcon className="w-5 h-5"/>Show the certificate</button>
                  </a>

                  <p
                    className={`mb-2 mt-2 text-base font-normal ${
                      isDarkMode ? "text-gray-400" : "text-gray-700"
                    }`}
                  >
                    Responsive Web Design
                  </p>
                  <a href={LINK_FREECODECAMP_RESPONSIVE}>
                  <button className={`flex gap-2 items-center  text-base border border-gray-200 px-3 py-2 rounded-md font-normal hover:bg-pink-600 hover:text-gray-50  ${
                      isDarkMode ? "text-gray-400" : "text-gray-700"
                    }`}><LinkIcon className="w-5 h-5"/>Show the certificate</button>
                  </a>
                
                </li>
                <li className="mb-5 ms-4 mx-5">
                <div
                    class={`absolute w-3 h-3 bg-pink-600 rounded-full mt-1.5 -start-1.5`}
                  ></div>
                  <time className="mb-1 text-sm font-normal leading-none text-gray-400">
                    2016 | Formal
                  </time>
                  <h3
                    className={`text-lg font-semibold  ${
                      isDarkMode ? "text-gray-300" : "text-gray-700"
                    }`}
                  >
                    Universitas Muslim Indonesia
                  </h3>
                  <p
                    className={`mb-4 text-base font-normal  ${
                      isDarkMode ? "text-gray-400" : "text-gray-700"
                    }`}
                  >
                    Computer Science / Informatics Engineering
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
